import React from "react";
import styled from "styled-components";
import { PersonTemplate } from "./PersonTemplate";
import { baseStyle } from "../assets/style/base";

/* IMAGES */
// import PB from "../assets/img/pb.jpg";
// import TH from "../assets/img/th.jpg";
// import HP from "../assets/img/hp.jpg";
// import SR from "../assets/img/sr.jpg";
// import BV from "../assets/img/bv.jpg";
// import VW from "../assets/img/vw.jpg";
// import MG from "../assets/img/mg.jpg";
// import PD from "../assets/img/pd.jpg";
// import MH from "../assets/img/mh.jpg";

export interface IEmployee {
  image: string;
  name: string;
  phonenumber: string;
  mail: string;
  title: string;
  titles: string[];
}

interface IEmployees {
  employees: IEmployee[] | undefined;
}

// const employees: IEmployee[] = [
//   {
//     image: PB,
//     name: "Petter Bogsti",
//     phonenumber: "479 28 230",
//     mail: "pb@ullernklinikken.no",
//     title: "Manuellterapeut",
//     titles: ["Idrettsmanuellterapeut", "Ultralydveiledet injeksjonsbehandling"],
//   },
//   {
//     image: TH,
//     name: "Turid Høysveen",
//     phonenumber: "479 28 232",
//     mail: "th@ullernklinikken.no",
//     title: "Manuellterapeut",
//     titles: [
//       "Spesialist innenfor idrettsfysioterapi",
//       "Master i helsefagvitenskap",
//     ],
//   },
//   {
//     image: HP,
//     name: "Hedevig Paus",
//     phonenumber: "479 28 233",
//     mail: "hp@ullernklinikken.no",
//     title: "Manuellterapeut",
//     titles: ["Veileder i manuellterapi", "Utdannet i barnemanuellterapi"],
//   },
//   {
//     image: SR,
//     name: "Sigmund Ruud",
//     phonenumber: "479 28 231",
//     mail: "sr@ullernklinikken.no",
//     title: "Manuellterapeut",
//     titles: ["VIdrettsfysioterapeut", "Grunnkurs kognitiv terapi"],
//   },
//   {
//     image: BV,
//     name: "Bente Viervoll",
//     phonenumber: "21 42 42 00",
//     mail: "post@ullernklinikken.no",
//     title: "Sekretær",
//     titles: [],
//   },
//   {
//     image: VW,
//     name: "Vibeke Wollen",
//     phonenumber: "479 28 234",
//     mail: "vw@ullernklinikken.no",
//     title: "Manuellterapeut",
//     titles: [
//       "Spesialist innenfor idrettsfysioterapi",
//       "Akupunktør, injeksjonsbehandling",
//     ],
//   },
//   {
//     image: MG,
//     name: "Morten Gåskjønli",
//     phonenumber: "905 80 257",
//     mail: "mg@ullernklinikken.no",
//     title: "Spesialist manuellterapi",
//     titles: ["Idrettsfysioterapeut FIFA"],
//   },
//   {
//     image: PD,
//     name: "Peter Døving",
//     phonenumber: "934 20 263",
//     mail: "pd@ullernklinikken.no",
//     title: "Manuellterapeut",
//     titles: ["Diagnotisk ultralyd", "Ultralydveiledet injeksjonsbehandling"],
//   },
//   {
//     image: MH,
//     name: "Morten Haugen",
//     phonenumber: "936 34 340",
//     mail: "mh@ullernklinikken.no",
//     title: "Manuellterapeut",
//     titles: [],
//   },
// ];

export function Employees({ employees }: IEmployees) {
  return (
    <>
      {employees && (
        <EmployeeWrapper>
          <div className="employee-title">Medarbeidere</div>
          <div className="employee-cards">
            {employees.map((employee, index) => (
              <PersonTemplate employee={employee} key={index} />
            ))}
          </div>
          <BLOCKSPACE />
        </EmployeeWrapper>
      )}
    </>
  );
}

const EmployeeWrapper = styled.div`
  margin-bottom: 9rem;

  .employee-title {
    text-align: center;
    padding: 5rem 0 6rem;
    color: ${baseStyle.mainColor};
    font-family: ${baseStyle.mainFont};
    font-weight: 400;
    font-size: 2.5rem;
  }

  .employee-cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 4rem;
    max-width: 1300px;
    margin: 0 auto;
  }
`;

const BLOCKSPACE = styled.div`
  height: 3rem;
`;

import { createClient } from "contentful";
import { config } from "../utils/config";

export async function getContent(entryId: string) {
  const client = createClient({
    host: config.contentfulHost,
    space: config.contentfulSpaceId,
    accessToken: config.contentfulAccessToken,
  });

  try {
    const result: any = await client.getEntry(entryId);

    return result;
  } catch (err) {
    return null;
  }
}

import React from "react";
import styled from "styled-components";
import LogoSvg from "../assets/logo/logo.svg";

export function Footer() {
  const date = new Date();
  const thisYear = date.getFullYear();

  return (
    <FooterWrapper>
      <div>
        <div className="flex-block">
          <div className="order-time">
            <h3>Bestille time</h3>
            <p>
              Timebestilling skjer på telefon eller e-post. Alle våre terapeuter
              har driftsavtale med Oslo kommune.
            </p>
          </div>
          <div className="reference">
            <h3>Mangler henvisning?</h3>
            <p>
              Du trenger ikke henvisning fra lege for å komme til undersøkelse
              og behandling hos oss. Manuellterapeuter kan sykmelde og henvise
              til spesialist og bildediagnostikk (MR, røntgen, scintigrafi).
            </p>
          </div>
        </div>
        <div>
          <p>
            <b>Telefon:</b> 21 42 42 00
            <br />
            <b>Adresse:</b> Ullern Allé 28
            <br />
            <b>Epost:</b> post@ullernklinikken.no
          </p>
        </div>
        <BLOCKSPACE />
        <hr />
        <div className="flex-copyright">
          <p className="copyright">
            {thisYear} © Ullernklinikken manuellterapi og rehabilitering
          </p>
          <div>
            <img src={LogoSvg} width="110px" alt="logo" />
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  background: #505050;
  color: white;
  padding: 2rem;

  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0 0 2rem;
  }

  hr {
    background: white;
  }

  div {
    max-width: 1140px;
    margin: 0;
    @media screen and (min-width: 800px) {
      margin: 0 auto;
    }

    .flex-block {
      display: block;

      @media screen and (min-width: 800px) {
        display: flex;
        gap: 2rem;
      }

      .order-time {
        max-width: 400px;
      }
      .reference {
        max-width: 720px;
      }
    }

    .flex-copyright {
      display: block;

      p {
        font-size: 12px;
        margin-bottom: 1rem;
      }
      div {
        text-align: left;
      }

      @media screen and (min-width: 500px) {
        display: flex;

        div {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
`;

const BLOCKSPACE = styled.div`
  height: 2rem;
`;

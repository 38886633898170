import React from "react";
import styled from "styled-components";
import { IEmployee } from "./Employees";
import { baseStyle } from "../assets/style/base";

export interface IPersons {
  employee: IEmployee;
  key: number;
}

export function PersonTemplate({ employee }: IPersons) {
  return (
    <PersonWrapper>
      <div className="image-frame">
        <img src={employee.image} alt="" />
      </div>
      <p className="name">{employee.name}</p>
      <hr />
      <div className="info-block">
        <p className="phonenumber">
          Tlf:{" "}
          <a href={`tel:+47${employee.phonenumber}`}>{employee.phonenumber}</a>
        </p>
        <p className="mail">Mail: {employee.mail}</p>
        <p className="title">{employee.title}</p>
        <ul>
          {employee.titles.map((title, index) => (
            <li key={index}>{title}</li>
          ))}
        </ul>
      </div>
    </PersonWrapper>
  );
}

const PersonWrapper = styled.div`
  padding: 0 1.6rem;
  margin-bottom: 3rem;
  width: 300px;

  .image-frame {
    border-radius: 50%;
    overflow: hidden;
    width: 200px;
    height: 200px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    color: ${baseStyle.mainColor};
    font-family: ${baseStyle.mainFont};
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
  }

  hr {
    height: 2px;
    background: ${baseStyle.mainColor};
  }

  .info-block {
    font-size: 1.1rem;
    color: ${baseStyle.thirdColor};

    p {
      margin: 0;

      a {
        color: ${baseStyle.secondaryColor};
        font-weight: 800;
      }
    }

    ul {
      margin: 0.2rem;
      padding-inline-start: 1.3rem;

      li {
        margin-bottom: 0.3rem;
      }
    }

    .phonenumber {
      margin: 0.8rem 0 0.2rem;
    }

    .mail {
      margin-bottom: 1rem;
    }
  }
`;

import { useState, useEffect } from "react";
import { getContent } from "./api/contentful";
import styled from "styled-components";
import LogoSvg from "./assets/logo/logo.svg";
import { baseStyle } from "./assets/style/base";

import { Employees, IEmployee } from "./components/Employees";
import { Footer } from "./components/Footer";

export interface Data {
  openingHour: string;
  employees: IEmployee[];
  serviceMessage: string;
}

export function App() {
  const [data, setData] = useState<Data | undefined>();

  useEffect(() => {
    getData("6nzYwvheMS2N27nckTaPtq");
  }, []);

  async function getData(entryId: string) {
    const result = await getContent(entryId);

    if (!result) return null;
    setData(result.fields);
  }

  return (
    <AppWrapper className="App">
      <div className="header">
        <img src={LogoSvg} width="130px" alt="logo" />
        <div className="header-content">
          <HeaderTitle>Velkommen til Ullernklinikken</HeaderTitle>
          <Text>
            Timebestilling skjer på telefon:{" "}
            <a href="tel:+4721424200">21 42 42 00</a>
          </Text>
          <ContentOpeningHour>{data?.openingHour}</ContentOpeningHour>
          {data?.serviceMessage && (
            <ContentServiceMessage>
              {data?.serviceMessage}
            </ContentServiceMessage>
          )}
        </div>
      </div>
      <Employees employees={data?.employees} />
      <Footer />
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  height: 100%;

  .header {
    img {
      padding: 2rem;
      position: absolute;
    }

    background: ${baseStyle.mainColor};
    height: 100%;
    position: relative;
    width: 100%;

    .header-content {
      align-items: flex-start;
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: relative;
      top: -1rem;

      @media screen and (min-width: 800px) {
        align-items: center;
      }
    }
  }
`;

const HeaderTitle = styled.h1`
  font-family: ${baseStyle.mainFont};
  font-size: 2.25rem;
  font-weight: 400;
  margin: 0 0 1rem;
  padding: 0 2rem;

  @media screen and (max-width: 321px) {
    font-size: 1.8rem;
  }

  @media screen and (min-width: 800px) {
    font-size: 3rem;
  }
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 0 0 1rem;
  padding: 0 2rem;
  width: 200px;

  a {
    color: ${baseStyle.secondaryColor};
    font-weight: 800;
  }

  @media screen and (min-width: 800px) {
    font-size: 1.3rem;
    width: auto;
  }
`;

const ContentOpeningHour = styled.p`
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 0 0 1rem;
  padding: 0 2rem;
  max-width: 270px;

  @media screen and (min-width: 800px) {
    font-size: 1.3rem;
    max-width: 580px;
    text-align: center;
    width: auto;
  }
`;

const ContentServiceMessage = styled.p`
  background: ${baseStyle.secondaryColor};
  color: white;
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 0 0 1rem 2rem;
  padding: 1rem;
  max-width: 270px;
  position: absolute;
  bottom: 4rem;

  @media screen and (max-width: 321px) {
    max-width: 218px;
    bottom: 0rem;
  }

  @media screen and (min-width: 767px) and (min-height: 800px) {
    font-size: 1.3rem;
    margin: 0;
    max-width: 580px;
    text-align: center;
    width: auto;
    bottom: 18rem;
  }

  @media screen and (min-width: 767px) and (max-height: 800px) {
    font-size: 1.3rem;
    margin: 0;
    max-width: 580px;
    text-align: center;
    width: auto;
    bottom: 10rem;
  }
`;
